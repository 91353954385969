<template>
  <v-card
    class="rounded-lg"
    height="calc(100vh - 64px - 60px)"
    style="overflow: auto"
    >
    <v-card-text
      class="pa-6 rounded-lg"
      >
      <div
        v-if="fair"
        >
        <div
          v-if="currentUser.selectedProfile.profilableType == 'Store'"
          >
          <div
            class="text-center"
            >
            <v-icon
              size="50"
              color="primary"
              >
              mdi-string-lights
            </v-icon>

            <div
              class="mb-6 text-h5 font-weight-bold black--text"
              >
              Detalle de la feria
            </div>

            <div
              class="mb-6"
              style="position: relative"
              >
              <v-avatar
                v-if="fair.image && fair.image.url"
                color="primary"
                size="130"
                >
                <v-img
                  :src="url + fair.image.url"
                  ></v-img>
              </v-avatar>

              <v-avatar
                v-else
                color="secondary"
                size="130"
                >
                <v-icon
                  color="white"
                  size="60"
                  >
                  mdi-string-lights
                </v-icon>
              </v-avatar>
            </div>

            <div
              class="text-h6 text-center mt-2 black--text font-weight-bold"
              >
              {{ fair.name }}
            </div>

            <div
              class="body-1 black--text"
              >
              {{ (new Date(fair.startsAt)).toLocaleDateString() }} al {{ (new Date(fair.endsAt)).toLocaleDateString() }}
            </div>

            <div
              class="body-1 black--text mt-2"
              >
              <v-icon color="primary" class="mt-n1">mdi-account</v-icon> {{ fair.owner }}
            </div>

            <div
              class="body-1 text-center mt-2 black--text"
              >
              {{ fair.description }}
            </div>

            <analytics
              class="mt-6"
              :fair="fair"
              ></analytics>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { Fair } from '@/graphql/queries/fairs/fairs'
import { JoinFair, LeaveFair } from '@/graphql/mutations/fairs/fair_stores'

const Analytics = () => import('@/components/fairs/Analytics')

export default {
  data: () => ({
    fair: null
  }),

  props: {
    id: {
      required: true,
      type: String | Number
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'url'])
  },

  created () {
    this.fetchFair()
  },

  watch: {
    id () {
      this.fetchFair()
    }
  },

  methods: {
    fetchFair () {
      this.$apollo.query({
        query: Fair,
        variables: {
          id: this.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.fair = res.data.fair
        this.loading = false
      })
    },

    joinFair () {
      this.$apollo.mutate({
        mutation: JoinFair,
        variables: {
          input: {
            id: this.id
          }
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.success = true
        this.fetchFair()
      })
    },

    leaveFair () {
      this.$apollo.mutate({
        mutation: LeaveFair,
        variables: {
          input: {
            id: this.id
          }
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.success = true
        this.fetchFair()
      })
    },
  },

  components: {
    Analytics
  }
}
</script>
